import React from "react";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AddLocationIcon from "@material-ui/icons/AddLocation";
import SettingsInputComponentIcon from "@material-ui/icons/SettingsInputComponent";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import PagesIcon from "@material-ui/icons/Pages";
import { NavLink } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import AssistantIcon from "@material-ui/icons/Assistant";

function Navmenu() {
  function mobileMenu() {
    var nevMenu = document.querySelector("div.nav__menu");
    nevMenu.style.transform = "translate(0%, 0px)";
    nevMenu.style.transition = "0.5s";
  }

  function mobileMenuClose() {
    var nevMenu = document.querySelector("div.nav__menu");
    nevMenu.style.transform = "translate(-100%, 0px)";
    nevMenu.style.transition = "0.5s";
  }

  return (
    <>
      <div className="nav__menu__container">
        <div className="humburber__menu" onClick={mobileMenu}>
          <MenuIcon />
        </div>
        <div className="nav__menu">
          <div className="humburder__menu__close" onClick={mobileMenuClose}>
            <CloseIcon />
          </div>
          <div className="logo">
            <img src="images/Asset-17.png" alt="" />
          </div>
          <div className="nav__list">
            <ul>
              <li>
                <NavLink to="/" target="_blank">
                  <PagesIcon />
                  Landing Page
                </NavLink>
              </li>
              <li>
                <NavLink to="user-list">
                  <AccountBoxIcon />
                  User List
                </NavLink>
              </li>
              <li>
                <NavLink to="eligible-User">
                  <AssistantIcon />
                  Eligible User
                </NavLink>
              </li>
              <li>
                <NavLink to="/add-location">
                  <AddLocationIcon />
                  Add New Location
                </NavLink>
              </li>
              <li>
                <NavLink to="/edit-location">
                  <SystemUpdateAltIcon />
                  Edit/Remove Locations
                </NavLink>
              </li>
              <li>
                <NavLink to="/api">
                  <SettingsInputComponentIcon />
                  Api Setup
                </NavLink>
              </li>
              <li>
                <NavLink to="myaccount">
                  <AccountCircleIcon />
                  My Account
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navmenu;
