import React, { Component } from "react";
import { authUser } from "./Firebase/Firebase";

class Login extends Component {
  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.signup = this.signup.bind(this);
    this.state = {
      email: "",
      password: "",
    };
  }
  login(e) {
    e.preventDefault();

    authUser
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then((u) => {
        console.log(u);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  signup(e) {
    e.preventDefault();
    authUser
      .createUserWithEmailAndPassword(this.state.email, this.state.password)
      .then((u) => {
        console.log(u);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  render() {
    return (
      <div className="login_container">
        <div className="login_box">
          <div className="logo_admin_panel">
            <img src="images/Asset-17.png" alt="logo" />
          </div>
          <center>
            <h3>Sign into your account</h3>
          </center>
          <form id="loginFormMain">
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter email address"
              onChange={this.handleChange}
              value={this.state.email}
              required
            />
            <input
              name="password"
              type="password"
              onChange={this.handleChange}
              id="password"
              placeholder="Enter password"
              value={this.state.password}
              required
            />
            <button className="login_button" onClick={this.login}>
              Login
            </button>
          </form>
          <center>
            <p>By signing in you agree to our Terms and Conditions</p>
          </center>
        </div>
      </div>
    );
  }
}
export default Login;
