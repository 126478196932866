import firebase from "firebase/app";
import 'firebase/firestore';
import "firebase/storage";
import 'firebase/auth'

var firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyDXf4Gnjilc9KFIyTgGzU5rEsxDb9X7m2E",
    authDomain: "fir-and-react-upload.firebaseapp.com",
    databaseURL: "https://fir-and-react-upload.firebaseio.com",
    projectId: "fir-and-react-upload",
    storageBucket: "fir-and-react-upload.appspot.com",
    messagingSenderId: "831045547038",
    appId: "1:831045547038:web:9071d6d85f83f659a1ea0f",
});

const db = firebaseApp.firestore();

const storage = firebase.storage();

const authUser = firebase.auth();

export { storage, db, authUser };