import React from "react";
import { db } from "../Firebase/Firebase";
import { useState, useEffect } from "react";

function Editpopup(props) {
  const [mydata, setMyData] = useState([]);
  const [inputvalue, setinputValue] = useState({});

  var editebleData = mydata.find((dat) => {
    return dat.id === props.mainDataId;
  });

  useEffect(() => {
    if (editebleData === undefined) {
    } else {
      setinputValue(editebleData);
    }
  }, [editebleData]);

  useEffect(() => {
    db.collection("MarketingBoost").onSnapshot((snapshot) =>
      setMyData(
        snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        })
      )
    );
  }, []);

  function submithandeler(e) {
    e.preventDefault();
    db.collection("MarketingBoost")
      .doc(inputvalue.id)
      .update({
        countryName: inputvalue.countryName,
        location: inputvalue.location,
        nights: inputvalue.nights,
        packageValue: inputvalue.packageValue,
        activationCost: inputvalue.activationCost,
        value: inputvalue.value,
        imageURL: inputvalue.imageURL,
      })
      .then(
        (document.querySelector(".model__container").style.display = "none")
      )
      .catch((error) => console.log(error));
  }

  return (
    <>
      <div className="model__container">
        <div className="model__box">
          <div className="close__btn">
            <span className="line1"></span>
            <span className="line2"></span>
          </div>
          <form className="marketingBoot__form">
            <img
              src={inputvalue.imageURL}
              alt={inputvalue.countryName}
              className="edit__Image__List"
            />
            <input
              type="text"
              value={inputvalue.countryName}
              onChange={(e) =>
                setinputValue({ ...inputvalue, countryName: e.target.value })
              }
              placeholder={inputvalue.countryName}
            />
            <input
              type="text"
              placeholder={inputvalue.location}
              value={inputvalue.location}
              onChange={(e) =>
                setinputValue({ ...inputvalue, location: e.target.value })
              }
            />
            <input
              type="text"
              placeholder={inputvalue.nights}
              value={inputvalue.nights}
              onChange={(e) =>
                setinputValue({ ...inputvalue, nights: e.target.value })
              }
            />
            <input
              type="currency"
              placeholder={inputvalue.packageValue}
              value={inputvalue.packageValue}
              onChange={(e) =>
                setinputValue({ ...inputvalue, packageValue: e.target.value })
              }
            />
            <input
              type="currency"
              placeholder={inputvalue.activationCost}
              value={inputvalue.activationCost}
              onChange={(e) =>
                setinputValue({ ...inputvalue, activationCost: e.target.value })
              }
            />
            <input
              type="number"
              placeholder={inputvalue.value}
              value={inputvalue.value}
              onChange={(e) =>
                setinputValue({ ...inputvalue, value: e.target.value })
              }
            />

            <button
              className="submit__btn"
              type="submit"
              onClick={submithandeler}
            >
              Update List
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default Editpopup;
