import React from "react";
import { useState } from "react";
import { storage, db } from "../Firebase/Firebase";
import "firebase/firestore";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Navmenu from "../Navmenu/Navmenu";

function Form() {
  const [data, setData] = useState({
    countryName: "",
    location: "",
    nights: "",
    packageValue: "",
    activationCost: "",
    value: "",
    imageFile: "",
    imageURL: "",
    activeClass: "",
  });

  const handleCountry = (e) => {
    setData({ ...data, countryName: e.target.value });
  };
  const handlepackageValue = (e) => {
    setData({ ...data, location: e.target.value });
  };

  const handleNights = (e) => {
    setData({ ...data, nights: e.target.value });
  };

  const handlePackageValue = (e) => {
    setData({ ...data, packageValue: e.target.value });
  };

  const handleActivationCost = (e) => {
    setData({ ...data, activationCost: e.target.value });
  };

  const handleValue = (e) => {
    setData({ ...data, value: e.target.value });
  };

  const handleImage = (e) => {
    if (e.target.files[0]) {
      setData({ ...data, imageFile: e.target.files[0] });
    }
  };

  const onSubmit = (e) => {
    document.querySelector(".loading__gif").style.display = "flex";
    e.preventDefault();
    setData({
      activeClass: "upload-image-none",
    });
    const uploadImage = storage
      .ref(`images/${data.imageFile.name}`)
      .put(data.imageFile);
    uploadImage.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        console.error(error);
      },
      () => {
        storage
          .ref("images")
          .child(data.imageFile.name)
          .getDownloadURL()
          .then((url) => {
            db.collection("MarketingBoost").add({
              countryName: data.countryName,
              location: data.location,
              nights: data.nights,
              packageValue: data.packageValue,
              activationCost: data.activationCost,
              value: data.value,
              imageURL: url,
            });
            document.querySelector(".loading__gif").style.display = "none";
            setData({
              countryName: "",
              location: "",
              nights: "",
              packageValue: "",
              activationCost: "",
              value: "",
              imageFile: "",
              imageURL: "",
              activeClass: "is-success",
            });
          });
      }
    );
  };

  return (
    <>
      <Navmenu />
      <div className="location__form">
        <h1>Add New Location</h1>
        <span></span>
        <hr />
        <span className={data.activeClass || "display__none"}>
          <CheckCircleOutlineIcon />
          Your list has been uploaded to the server
        </span>

        <form className="marketingBoot__form" onSubmit={onSubmit}>
          <input
            value={data.countryName}
            type="text"
            placeholder="Country Name"
            onChange={handleCountry}
            required
          />
          <input
            value={data.location}
            type="text"
            placeholder="Location"
            onChange={handlepackageValue}
            required
          />
          <input
            value={data.nights}
            type="text"
            placeholder="Nights"
            onChange={handleNights}
            required
          />
          <input
            value={data.packageValue}
            type="currency"
            placeholder="Total Package Value"
            onChange={handlePackageValue}
            required
          />
          <input
            value={data.activationCost}
            type="currency"
            placeholder="Total Activation Costs"
            onChange={handleActivationCost}
            required
          />
          <input
            value={data.value}
            type="number"
            placeholder="API Value"
            onChange={handleValue}
            required
          />
          <input
            id="file__Upload"
            type="file"
            onChange={handleImage}
            placeholder="Supported image size 400px*300px"
            required
          />
          <button
            className="submit__btn"
            type="submit"
            // onClick={submithandeler}
          >
            Add A New Location
            <img src="/images/loading2.gif" className="loading__gif" />
          </button>
        </form>
      </div>
    </>
  );
}

export default Form;
