import React from "react";

function ThankYou() {
  return (
    <>
      <div className="thank_you__page">
        <div className="thank__you__content">
          <h1>Congratulations!</h1>
          <p className="on__prosses">
            We are processing your vacation request. You will receive en email
            from our partner Redeem Vacations shortly
          </p>
        </div>
      </div>
    </>
  );
}

export default ThankYou;
