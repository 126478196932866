import React from "react";
import Navmenu from "../Navmenu/Navmenu";
import { db } from "../Firebase/Firebase";
import { useState, useEffect } from "react";
import FindInPageIcon from "@material-ui/icons/FindInPage";

function EligibleUsers() {
  const [mydata, setMyData] = useState([]);

  //data recive from firebase store
  useEffect(() => {
    db.collection("eligibleUserlist").onSnapshot((snapshot) =>
      setMyData(
        snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        })
      )
    );
  }, []);

  //Data delete from firebase store
  function deleteHandeler(e) {
    e.preventDefault();
    let getTdId = e.target.parentElement.parentElement.id;
    e.target.parentElement.classList.add("deleteAnimationTest");
    db.collection("eligibleUserlist").doc(getTdId).delete();
  }

  //table Data Search
  function dataSearchFromTable() {
    // Declare variables
    var input = document.getElementById("myInput");
    var filter = input.value.toUpperCase();
    var table = document.getElementById("myTable");
    var trs = table.tBodies[0].getElementsByTagName("tr");

    // Loop through first tbody's rows
    for (var i = 1; i < trs.length; i++) {
      // define the row's cells
      var tds = trs[i].getElementsByTagName("td");

      // hide the row
      trs[i].style.display = "none";

      // loop through row cells
      for (var i2 = 0; i2 < tds.length; i2++) {
        // if there's a match
        if (tds[i2].innerHTML.toUpperCase().indexOf(filter) > -1) {
          // show the row
          trs[i].style.display = "";

          // skip to the next row
          continue;
        }
      }
    }
  }

  return (
    <>
      <Navmenu />
      <div className="Location__Page__Body">
        <h1> Eligible User List </h1>
        <span></span>
        <div className="search-by-item">
          <input
            type="text"
            id="myInput"
            placeholder="Search for item.."
            title="Type in a name"
            onChange={dataSearchFromTable}
          ></input>
          <FindInPageIcon />
        </div>
        <table id="myTable">
          <tbody>
            <tr className="header">
              <th>Eligible User Name</th>
              <th>Eligible User Email</th>
              <th>Eligible User Phone</th>

              <th className="table__action">Action</th>
            </tr>
            {mydata.map((data) => (
              <tr key={data.id} id={data.id}>
                <td>{data.Name}</td>
                <td>{data.Email}</td>
                <td>{data.Phone}</td>
                <td className="delete__btn__td">
                  <button className="delete__btn" onClick={deleteHandeler}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default EligibleUsers;
