import React from "react";
import { db } from "../Firebase/Firebase";
import { useState, useEffect } from "react";
import LandingPopup from "../LandingPage/LandingPopup";

function AllCountryCards(props) {
  const [mydata, setMyData] = useState([]);

  //Pass Popup values
  const [findData, setFindData] = useState({
    activationCost: "",
    location: "",
    nights: "",
    packageValue: "",
    value: "",
    countryName: "",
  });

  //Data Derict filter
  let DataFilterByCountryWise = mydata.filter((data) => {
    if (props.countryWiseName) {
      return data.countryName === props.countryWiseName;
    } else {
      return data;
    }
  });

  //data recive from firebase store
  useEffect(() => {
    db.collection("MarketingBoost").onSnapshot((snapshot) =>
      setMyData(
        snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        })
      )
    );

    setTimeout(function () {
      document.querySelector(".loading_snipet").style.display = "none";
      document.querySelector(".location__card").style.display = "flex";
    }, 3000);
  }, []);

  //popup conect to card
  function showPopup(e) {
    e.preventDefault();

    let foundedValueWiseData = mydata.find((valueWiseData) => {
      return valueWiseData.value === e.target.id;
    });

    setFindData({
      activationCost: foundedValueWiseData.activationCost,
      location: foundedValueWiseData.location,
      nights: foundedValueWiseData.nights,
      packageValue: foundedValueWiseData.packageValue,
      value: foundedValueWiseData.value,
      countryName: foundedValueWiseData.countryName,
    });

    document.querySelector(".model__container").style.display = "flex";
    // document.querySelector(".model__box").style.transition = "all 0.3s ease-in";

    setTimeout(function () {
      document.querySelector(".model__box").style.transform =
        "translate(0, 0%)";
    }, 200);
    var closeBtn = document.querySelector(".close__btn");
    closeBtn.addEventListener("click", function () {
      document.querySelector(".model__box").style.transform =
        "translate(0px, -200%)";
      setTimeout(function () {
        document.querySelector(".model__container").style.display = "none";
      }, 200);
    });
  }

  return (
    <>
      <LandingPopup
        activationCost={findData.activationCost}
        location={findData.location}
        nights={findData.nights}
        packageValue={findData.packageValue}
        value={findData.value}
        countryName={findData.countryName}
      />
      <div className="location__card__lists">
        {DataFilterByCountryWise.map((location) => (
          <div
            key={location.id}
            className="location__list"
            id={location.location}
          >
            <div className="img">
              <img src={location.imageURL} alt={location.location} />
            </div>
            <div className="text__box">
              <h3>
                <span className="selected__location__Name">
                  {location.location}
                </span>
                <span> {location.nights} </span>
              </h3>
              <p>
                <span> Retail Value: </span>
                <span> {location.packageValue} </span>
              </p>
              <p>
                <span> Tax and Booking Fees: </span>
                <span> {location.activationCost} </span>
              </p>
            </div>
            <div className="book_this_trip">
              <button
                onClick={showPopup}
                className="trip_btn"
                id={location.value}
              >
                Book This Trip
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default AllCountryCards;
