import React, { useState, useEffect } from "react";
import { db } from "../Firebase/Firebase";
import Navmenu from "../Navmenu/Navmenu";

function ApiSetup() {
  const [mbInit, setmbInit] = useState([]);
  const [getmbInit, setgetmbInit] = useState([]);

  const submitHandeler = (e) => {
    e.preventDefault();

    db.collection("mbapi")
      .doc("zANl1fXo9mm00D6ybzsl")
      .update({
        sender: mbInit.sender,
        BusinessId: mbInit.BusinessId,
        email: mbInit.email,
      })
      .then(
        setmbInit({
          sender: "",
          BusinessId: "",
          email: "",
        })
      )
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    db.collection("mbapi").onSnapshot((snapshot) => {
      setgetmbInit(snapshot.docs.map((doc) => doc.data()));
    });
  }, []);

  return (
    <>
      <Navmenu />
      <div className="location__form">
        <h1>Connect Your Marketing Boost API</h1>
        <span></span>
        <hr />
        <form className="marketingBoot__form">
          <input
            onChange={(e) => setmbInit({ ...mbInit, sender: e.target.value })}
            type="text"
            placeholder="MB Sender Number"
            required
          />
          <input
            onChange={(e) =>
              setmbInit({ ...mbInit, BusinessId: e.target.value })
            }
            type="text"
            placeholder="MB Business ID"
            required
          />
          <input
            onChange={(e) => setmbInit({ ...mbInit, email: e.target.value })}
            type="email"
            placeholder="MB Business Email"
            required
          />

          <button
            onClick={submitHandeler}
            className="submit__btn"
            type="submit"
          >
            Connect Api
          </button>
        </form>
        <div className="user__info">
          {getmbInit.map((usr) => (
            <div className="user_detail" key={"userDetails"}>
              <center>
                <h2>Connected Marketing Boost API:</h2>
              </center>
              <p>
                <strong>User Email:</strong> {usr.email}
              </p>
              <p>
                <strong>User Business Id:</strong> {usr.BusinessId}
              </p>
              <p>
                <strong>User sender:</strong> {usr.sender}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default ApiSetup;
