import React from "react";
import Navmenu from "../Component/Navmenu/Navmenu";

function Welcome() {
  return (
    <>
      <Navmenu />
      <div className="welcome_page">
        <h1>Welcome to Happy Arrival</h1>
      </div>
    </>
  );
}

export default Welcome;
