import React, { useState, useEffect } from "react";
import { db } from "../Firebase/Firebase";
import { useHistory } from "react-router-dom";
import { fbDERedeemVacation } from "../ajax/ajax";
import { useForm } from "react-hook-form";

function LandingPopup({
  activationCost,
  location,
  nights,
  packageValue,
  value,
  countryName,
}) {
  const { register, handleSubmit, errors } = useForm();

  // const [mydata, setMyData] = useState([]);
  const [inputValue, setinputValue] = useState([]);

  const [getmbInit, setgetmbInit] = useState([]);
  const sender = getmbInit.map((sun) => sun.sender);
  const BusinessId = getmbInit.map((bus) => bus.BusinessId);
  const [checkUserList, setcheckUserList] = useState([]);
  const [EligibleUserList, SetEligibleUserList] = useState([]);

  const apiValue = value;

  var history = useHistory();

  //Date Setup
  function formatDate(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getMonth() +
      1 +
      "/" +
      date.getDate() +
      "/" +
      date.getFullYear() +
      " " +
      strTime
    );
  }

  var d = new Date();
  var eDateSetup = formatDate(d);

  //data recive from firebase store
  useEffect(() => {
    db.collection("userList").onSnapshot((snapshot) =>
      setcheckUserList(
        snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        })
      )
    );

    db.collection("eligibleUserlist").onSnapshot((snapshot) =>
      SetEligibleUserList(
        snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        })
      )
    );

    db.collection("mbapi").onSnapshot((snapshot) => {
      setgetmbInit(snapshot.docs.map((doc) => doc.data()));
    });
  }, []);

  //User List extract from data base
  const ExtractUserEmail = checkUserList.map((emailList) => {
    return emailList.userEmail;
  });

  //User List extract from data base
  const EligibleUserEmail = EligibleUserList.map((emailList) => {
    return emailList.Email;
  });

  const EligibleUserEmailMatched = EligibleUserEmail.includes(
    inputValue.userEmail
  );
  const conditionMatched = ExtractUserEmail.includes(inputValue.userEmail);

  const onSubmit = () => {
    //Condition User Check List
    if (conditionMatched === true) {
      document.querySelector(".Founded_Message").style.display = "flex";
    } else {
      if (EligibleUserEmailMatched === true) {
        document.querySelector(".Founded_Message").style.display = "none";

        //Send User Information to database
        db.collection("userList")
          .add(inputValue)
          .then((res) => {})
          .catch((error) => console.log(error));

        //MB official API passing
        var fdDE_detail = {
          fbDE_sender: sender[0],
          fbDE_fullname: inputValue.Fname,
          fbDE_email: inputValue.userEmail,
          fbDE_destination: apiValue,
          fbDE_business: BusinessId[0],
          fbDE_message: "",
          fbDE_phone: "",
        };
        fbDERedeemVacation(fdDE_detail);

        //Redirect Thank you Page
        setTimeout(function () {
          history.push("/thankyou");
        }, 1000);
      } else {
        alert("You are not eligible for this vacation incentive");
      }
    }
  };

  return (
    <>
      <div className="model__container">
        <div className="model__box">
          <div className="close__btn">
            <span className="line1"> </span> <span className="line2"> </span>
          </div>
          <div className="Founded_Message">
            We're sorry, our records indicate that you have already received a
            vacation incentive
          </div>
          <div className="vaction__headline">
            <h2>Where Should We Send Your Complimentary Voucher </h2>
          </div>
          <div className="selected__location__detailes">
            <div className="location_Night">
              <span>{location}</span>
              <span>{nights}</span>
            </div>
            <div className="retail__value">
              <span>Retail Value:</span>
              <span>{packageValue}</span>
            </div>
            <div className="tax__and__booking__fee">
              <span>Tax And Booking Fee:</span>
              <span>{activationCost}</span>
            </div>
          </div>
          <form
            className="marketingBoot__form"
            action="/thankyou"
            method="POST"
            onSubmit={handleSubmit(onSubmit)}
          >
            <input
              type="text"
              placeholder="Enter your full name..."
              name="Full Name"
              onChange={(e) =>
                setinputValue({
                  ...inputValue,
                  Fname: e.target.value,
                  EmailDate: eDateSetup,
                  Location: location,
                  Nights: nights,
                  PackageValue: packageValue,
                  Value: value,
                  ActivationCost: activationCost,
                  Country: countryName,
                })
              }
              ref={register({ required: true, maxLength: 80 })}
            />
            <input
              type="text"
              placeholder="Enter your email..."
              name="Email"
              onChange={(e) =>
                setinputValue({ ...inputValue, userEmail: e.target.value })
              }
              ref={register({ required: true, pattern: /^\S+@\S+$/i })}
            />
            <p>{errors.email && errors.email.message}</p>
            <input
              type="tel"
              placeholder="Enter your phone number..."
              name="Mobile number"
              onChange={(e) =>
                setinputValue({ ...inputValue, userPhone: e.target.value })
              }
              ref={register({ required: true, minLength: 6, maxLength: 12 })}
            />
            <button className="redeem__btn" type="submit">
              Redeem Now
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default LandingPopup;
