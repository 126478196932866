import React from "react";

function Faq() {
  return (
    <div>
      <div className="faq__header">
        <h1>FREQUENTLY ASKED QUESTIONS</h1>
      </div>
      <div className="faq__body">
        <p>
          Congratulations! The company that gave you this gift voucher
          pre-purchased this voucher for you and it is being fulfilled by the
          RedeemVacations.com, "The Leader In Travel Incentives Fulfillment ".
          Here is some answers to common questions that you may have.
        </p>
        <h3>
          01. Do I have to take a timeshare presentation or other sales preview
          in order to take advantage of this vacation voucher?
        </h3>
        <p>
          <strong>No,</strong> you will never be required to attend any sales or
          timeshare presentation, there are no hoops to jump through and we make
          it extremely easy to use your travel voucher. The company that gave
          you this vacation voucher pre-purchased this hotel stay voucher for
          you and it is being fulfilled by RedeemVacations.com.
        </p>
        <h3>02. What does it mean that this expires in 7 days?</h3>
        <p>
          The incentive voucher will expire in 7 days, however, once you
          activate your vacation voucher will have at 18 months to travel. You
          may activate a voucher by simply paying the taxes and fees associated
          with the complimentary hotel stay (approximately $20/night). Then you
          can log in to RedeemVacations.com and search for your preferred travel
          dates and participating hotels/resorts online.
        </p>
        <h3>03. How do I book my travel dates after I activate?</h3>
        <p>
          It is simple, all we ask is that you give us a minimum of 30 days
          advance notice to secure your travel dates. This means, for example,
          you can book dates starting 31 days from today or 45 days from today,
          90 days or more. All you will need to do after activating the voucher
          is log back in from the link in your email receipt and begin searching
          for your preferred travel dates. Once you choose the dates you will be
          shown the participating hotels/resorts based on availability for your
          chosen dates. We recommend searching midweek travel dates or Sundays
          through Thursday check in dates and avoid weekends and holidays as
          these may have fewer hotel choices or may not be available. Some
          weekends may only be available with a small surcharge.
        </p>
        <h3>04. Can I travel with my children?</h3>
        <p>
          YES please bring your kids! Our hotel and resort options will mostly
          include up to 4 people in the room, regardless of age. One person in
          the party needs to be over the age of 21. Each participating Hotel or
          Resort may have different room types available depending on your
          preferred destination and travel dates, all of them will accommodate 2
          adults. If traveling with extra adults or children you may need to be
          flexible on travel dates or upgrades may be available to accommodate
          depending on availability. Optional Upgrades require the purchase of
          additional rooms or larger suites.
        </p>
        <h3>05. Are flights included in these travel incentives?</h3>
        <p>
          No. Flights are not included. The recipient of this voucher is
          responsible for all expenses including but not limited to: airfare,
          transfers, food, gratuities, and any other miscellaneous expenses.
          This voucher cannot be combined with any other promotional vacation
          offer.
        </p>
        <h3>06. How are you able to offer these travel incentives?</h3>
        <p>
          RedeemVacations.com has traveled over 1 million families to date
          thanks to our vast network of insiders that negotiate great prices on
          unsold accommodations at resort hotspots around the world. We help
          fill what would otherwise be empty hotel rooms in which the
          hotels/resorts turn into additional revenue from other areas of their
          property and future bookings. Hotels rarely experience full occupancy,
          yet they remain open, providing rooms and services at fixed costs. So
          when hotels sell us their room inventory at big discounts by
          partnering with RedeemVacations.com, they receive less room revenue
          than normal however they already didn't have any in the first place as
          we are helping fill unsold rooms, however with a RedeemVacations.com
          customer at least now they have the opportunity to earn revenue from
          their in-house restaurants, bars, spas, room service, casinos, an
          extra nights stay, future bookings, referrals and more.
          RedeemVacations will also offer you additional value with savings on
          options to enhance your vacation for you to consider such as
          attraction tickets larger room types additional nights and more.
        </p>
        <h3>
          07. What are the taxes and fees and what are they based on for the
          travel vouchers.
        </h3>
        <p>
          The taxes and fees associated with the activation of the vacation
          incentive are to cover the local tourism and bed taxes, government
          sales tax or value-added taxes and these are based on the average full
          retail rate of the accommodations.
        </p>
        <h3>08. Are there any other expenses/costs to use this vacation?</h3>
        <p>
          The recipient of this voucher will receive free room rate
          accommodations free of charge, which retail is valued from $150.00 per
          night to up to $350.00 per night, however, recipient is responsible
          for all other expenses including but not limited to: airfare,
          transfers, food & beverages, gratuities, resort fees if applicable,
          and any other personal or miscellaneous expenses. Resort fees are
          commonplace in the travel industry. As a matter of fact, over 96% of
          tourists traveling to Orlando or Las Vegas now have to pay a resort
          fee when they check-in at the resort. This is so common now that it’s
          just expected for any seasoned traveler. Resort fees if charged cover
          items like WiFi Internet Access, Local Calls. Resort amenities,
          Fitness centers, in-room safes, Beach Chairs, Umbrellas, Beach/Pool
          Towels, activities and more. As a recipient of this voucher, you are
          being offered complimentary hotel accommodations portion of your
          vacation that is valued at up to $350 US per night depending on
          destination and time of year. Your only expenses other than the
          activation fee which covers the room taxes will be your transportation
          to and from the destination and to and from the hotel as well as food
          and beverages and any other personal miscellaneous expenses.
        </p>
        <h3>
          09. Can I get together and book several rooms with others who have a
          travel voucher?
        </h3>
        <p>
          These travel incentive vouchers cannot be used for groups of more than
          1 room. The hotels that are offering this promotion will honor only
          one voucher package per family, friends, acquaintances or known groups
          traveling on the same, similar and/or overlapping arrival and
          departure dates. There are additional costs associated with group
          travel. They also will not be available to use during conventions or
          other special events. Group travel bookings risk all reservations
          being canceled, this is one rule that many try to circumvent and we
          strongly recommend that if you need more than one room you only use
          one travel voucher and purchase the additional rooms at our discounted
          rates. If you have more than one travel incentive they cannot be used
          back to back in the same or different destination they cannot be used
          back to back to extend travel.
        </p>
        <h3>10. What are the cancellation and refund policies?</h3>
        <p>
          Once activated the travel incentive will provide you with 18 months to
          travel and you will be able to choose from all available destinations
          to find travel dates and participating resort/hotel options. The
          activation fees are non-refundable and once travel dates are confirmed
          no changes are allowed. Cancellations after dates are confirmed will
          result in forfeiture of the activation fees. (The inventory used for
          these rooms are not as flexible as booking at regular retail rates,
          once confirmed changes will not be allowed and cancellations or
          no-shows will result in forfeiture of fees paid) If optional upgrades
          are selected the cancelation or rescheduling policies will depend upon
          the individual participating hotel.
        </p>
        <h3>11. What are the exact terms and conditions of the incentives?</h3>
        <br />
        <p>
          <strong> Terms and Conditions </strong> <br /> <br />
          There are NO timeshare presentations required to use this vacation
          incentive or any other hoops you will need to jump to redeem. Simply
          activate online by prepaying the hotel taxes and fees associated with
          the destination. After activation, login online and search for your
          preferred participating resort/hotel and preferred travel dates.
          <br /> <br />
          The expiration date on this incentive pertains only to the redemption
          or activation of this incentive and not actual travel. You must
          activate this incentive within 7 days of receiving it. Travel can be
          completed within 18 months after activation. This incentive cannot be
          combined with any other hotel stay or promotional vacation offer. This
          incentive cannot be used in conjunction with another similar or same
          promotional offer Reedeemvacations.com will only honor one incentive
          per family, friends, acquaintances or known groups traveling on the
          same, similar and/or adjoining arrival and departure dates. If you
          have more than one vacation incentive they cannot be used back to back
          in the same or different destinations.
          <br /> <br />
          GROUP travel is NOT allowed only one incentive per household and
          resort/hotel is allowed. Reedeemvacations.com will only honor one
          incentive per family, friends, acquaintances or known groups traveling
          on the same, similar and/or adjoining arrival and departure dates.
          Unfortunately, only 1 party among friends, family, acquaintances or
          known groups can use the complimentary vacation incentive for the same
          or overlapping travel dates. Otherwise, there are additional costs
          associated with group travel.
          <br /> <br />
          This digital incentive will be considered void if bartered or
          purchased by the recipient. The incentive is valid for a reservation
          request offering Luxurious Resort/Hotel Accommodations in your choice
          of destinations provided by RedeemVacations.com These rooms are for 2
          to 4 occupants based on room type and availability and at least one
          occupant must be at least 21 years of age. Your cost to redeem this
          incentive and book the reservation is just our recovery fees for the
          local government taxes and tourism fees which vary by destination.
          Main reservation holder must also have a Major credit or a bank debit
          card with a Visa or Mastercard logo, plus a valid government-issued ID
          to check-in.
          <br /> <br />
          The recipient of this incentive will receive luxury hotel
          accommodation room rate free of charge, which retail is valued at up
          to $350 US per night, however recipient is responsible for all other
          expenses including but not limited to: airfare, transfers, food &
          beverages, gratuities, resort fees if applicable and any other
          personal or miscellaneous expenses. (Resort fees are now commonplace
          in hotels around the world however not always applicable. For example
          in Orlando FL they run from $5.00 to $13.00 per night, In Las Vegas
          resort fees run from $16.00 to $38 per night Paid directly to the
          resort. If applicable the resort fees will be disclosed when choosing
          a hotel and preferred travel dates. Resort fees cover items like WiFi
          Internet Access, Local Calls, & Resort amenities).
          <br /> <br />
          All reservations require a minimum of 30 days advance notice to book.
          Reservations are based on availability and will not be available
          during major holidays or resort special events. Check-in is available
          7 days a week.
          <br /> <br />
          This incentive activation is FINAL & NON-REFUNDABLE end user will have
          18 months to travel and can choose from all available destinations to
          find travel dates and participating resort/hotel options. Once travel
          dates are confirmed no changes will be allowed. Cancellations after
          dates are confirmed will result in forfeiture of the activation fees.
          (The inventory used for these rooms are not as flexible as booking at
          regular retail rack rates, once confirmed changes will not be allowed
          and cancellations or no-shows will result in forfeiture of fees paid)
          <br /> <br />
          The user must reside at least 100 miles or 160 kilometers away from
          the destination resort of choice or choose a different resort offer
          from the available destination options.
          <br /> <br />
          This incentive has no cash surrender value, void if discontinued and
          where prohibited by law. The terms of this offer are subject to change
          without notice. RedeemVacations.com reserves the right to offer other
          hotels with equal or similar value with incentive holder approval if
          required due to availability. Do not rely upon representations other
          than what is printed on this incentive.
          <br /> <br />
          To redeem simply activate the incentive online and then search online
          at RedeemVacations.com for the destination of choice and participating
          resort/hotels and travel dates.
        </p>
      </div>
    </div>
  );
}

export default Faq;
