import React from "react";
import AllCountryCards from "../Locations/AllCountryCards";
import { db } from "../Firebase/Firebase";
import { useState, useEffect } from "react";
import Loading from "../Loading";
import { NavLink } from "react-router-dom";

function LandingPage() {
  const [countryName, setCountryName] = useState("");
  const [mydata, setMyData] = useState([]);
  const onlyCountryName = [...new Set(mydata.map((dat) => dat.countryName))];

  // Alphabetical sort
  onlyCountryName.sort();

  //data recive from firebase store
  useEffect(() => {
    db.collection("MarketingBoost").onSnapshot((snapshot) =>
      setMyData(
        snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        })
      )
    );
  }, []);
  //

  return (
    <>
      <div className="hero__header">
        <div className="hero__row">
          <div className="hero__col">
            <h1>Redeem Your Complimentary Vacation</h1>
          </div>
        </div>
      </div>

      <div className="landing__Text">
        <div className="landing__Text__Row">
          <div className="landing__Text__Col">
            <h1>Congratulations! Let’s book your trip.</h1>
            <p>
              The company that gave you this gift voucher pre-purchased this
              voucher for you and it is being fulfilled by RedeemVacations.com,
              "The Leader In Travel Incentives Fulfillment". <br /> <br />
              Simply select your preferred travel destination, and ONLY pay the
              taxes required and you're on your way to an amazing complimentary
              hotel stay anytime in the next 24 months based on availability.
            </p>
          </div>
        </div>
      </div>
      <div className="loading_snipet">
        <Loading />
      </div>
      <div className="location__card">
        <div className="location__card__row">
          <div className="loation__card__col__cuntry">
            <div className="all__country__list">
              {" "}
              <h3>Select Your Country</h3>
              {onlyCountryName.map((conty) => (
                <button
                  key={conty}
                  className="Country__List__Btn"
                  onClick={(e) => setCountryName(e.target.innerText)}
                >
                  {conty}
                </button>
              ))}
            </div>
            <div className="faq__page__Link">
              <NavLink to="/faq">
                <h3>FAQ</h3>
              </NavLink>
            </div>
          </div>
          <div className="loation__card__col">
            <AllCountryCards countryWiseName={countryName} key={mydata.id} />
          </div>
        </div>
      </div>
    </>
  );
}

export default LandingPage;
