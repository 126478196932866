import React, { useState, useEffect } from "react";
import "./App.css";
import Welcome from "./Component/Welcome";
import Form from "./Component/Form/Form.jsx";
import LanndingPage from "./Component/LandingPage/LandingPage";
import EditLocation from "./Component/EditLocation/EditLocation";
import ApiSetup from "./Component/APISetup/ApiSetup";
import MyAccount from "./Component/MyAccount/MyAccount";
import ThankYou from "./Component/ThankYou/ThankYou";
import { Switch, Route } from "react-router-dom";
import { authUser } from "./Component/Firebase/Firebase";
import Login from "./Component/Login";
import UserLisr from "./Component/UserList/UserLisr";
import Faq from "./Component/LandingPage/Faq";
import EligibleUsers from "./Component/EligibleUsers/EligibleUsers.jsx";

function App() {
  const [user, setUser] = useState("");

  function authListener() {
    authUser.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser("");
        document.querySelector(".login_container").style.display = "flex";
      }
    });
  }

  useEffect(() => {
    authListener();
  }, []);

  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={LanndingPage} />
        <Route path="/thankyou" component={ThankYou} />
        <Route path="/faq" component={Faq} />
        {user ? <Route path="/user-list" component={UserLisr} /> : <Login />}
        {user ? (
          <Route path="/eligible-User" component={EligibleUsers} />
        ) : (
          <Login />
        )}
        {user ? <Route path="/admin" component={Welcome} /> : <Login />}
        {user ? <Route path="/add-location" component={Form} /> : <Login />}
        {user ? (
          <Route path="/edit-location" component={EditLocation} />
        ) : (
          <Login />
        )}
        {user ? <Route path="/api" component={ApiSetup} /> : <Login />}
        {user ? <Route path="/myaccount" component={MyAccount} /> : <Login />}
      </Switch>
    </div>
  );
}

export default App;
