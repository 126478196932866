import React from "react";
import Navmenu from "../Navmenu/Navmenu";

import { authUser } from "../Firebase/Firebase";
function MyAccount() {
  function signOutHandeler() {
    authUser
      .signOut()
      .then(function () {
        // Sign-out successful.
      })
      .catch(function (error) {
        // An error happened.
      });
  }

  return (
    <>
      <Navmenu />
      <div className="My__Account__Page">
        <h1>My Account</h1>
        <span></span>
        <button onClick={signOutHandeler}>Sign Out</button>
      </div>
    </>
  );
}

export default MyAccount;
