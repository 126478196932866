import React from "react";

function Loading() {
  return (
    <div id="outer">
      <div id="middle">
        <div id="inner"></div>
      </div>
    </div>
  );
}

export default Loading;
