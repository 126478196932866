export function fbDERedeemVacation(businessdata) {
    var data = {
        business: businessdata.fbDE_business,
        sender: businessdata.fbDE_sender,
        destination: businessdata.fbDE_destination,
        name: businessdata.fbDE_fullname,
        email: businessdata.fbDE_email,
        message: businessdata.fbDE_message,
        countrycode: businessdata.fbDE_countrycode,
        phone: businessdata.fbDE_phone
    };

    var url = 'https://members.marketingboost.com/api/redeem-vacation/send';

    //var url = 'http://marketingboost-member/api/redeem-vacation/send';

    var success = function(data) {
        console.log(JSON.parse(data));
    };

    function uriData(data) {
        return Object.keys(data)
            .map(function(k) {
                return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]);
            })
            .join('&');
    }

    var params = typeof data == 'string' ? data : uriData(data);
    console.log(params);


    var xhr = new XMLHttpRequest();

    xhr.open('POST', url);
    xhr.onreadystatechange = function() {
        if (xhr.readyState > 3 && xhr.status === 200) {
            success(xhr.responseText);
        }
    };

    // xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.send(params);

    return xhr;
}